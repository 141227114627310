// StaticDataContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../utilis/apiPath";

export const StaticDataContext = createContext();

export const StaticDataProvider = ({ children }) => {
  const [apiData, setData] = useState({
    about: null,
    projects: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch all static data in one request
    const fetchData = async () => {
      try {
        setLoading(true);

        // Parallel API calls
        const [aboutResponse, projectsResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/about`),
          axios.get(`${API_BASE_URL}/projects-short-details`),
        ]);

        setData({
          about: aboutResponse.data.data,
          projects: projectsResponse.data.projectData,
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <StaticDataContext.Provider
      value={{
        apiData,
        loading,
        error,
        projects: apiData.projects,
        about: apiData.about,
      }}
    >
      {children}
    </StaticDataContext.Provider>
  );
};
