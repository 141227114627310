import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Banner from "./components/Banner";
import ProjectImage from "./components/ProjectImage/Index";
import ProductDetails from "./components/ProductDetails";
import MyPortfolio from "./components/MyPortfolio";
import Cta from "./components/Cta/Index";
import Aos from "aos";
import { useParams } from "react-router-dom";
import { API_BASE_URL } from "./utilis/apiPath";
import { StaticDataContext } from "./context/StaticDataContext ";

const ProductDetail = () => {
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { apiData } = useContext(StaticDataContext);
  const pagedata = apiData.about;

  const { slug } = useParams();

  useEffect(() => {
    // Initialize AOS
    Aos.init({
      // Global settings for AOS
    });

    // Fetch project details
    const fetchProjectDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/projects/${slug}`);
        setProductData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load project details");
        setLoading(false);
      }
    };

    if (slug) {
      fetchProjectDetails();
    }
  }, [slug]);

  // Loading state
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-white">
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-white">
        {error}
      </div>
    );
  }

  // Not found state
  if (!productData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-white">
        Project not found
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="min-h-screen flex flex-col items-center bg-[#111111] overflow-visible p-0 relative">
        <Banner
          title={productData.title}
          description={productData.description[0]} // Assuming description is an array
        />
        <ProjectImage
          mockupImage={productData.imgSrc}
          fullImage={productData.imgSrcFullpage}
          title={productData.title}
        />
        <ProductDetails productData={productData.productData} />
        <MyPortfolio
          shuffle={true}
          currentProjectSlug={slug}
          limit={4}
          title="Explore More Projects"
          width={"lg:w-[47%] w-full flex flex-wrap "}
          parentClass={
            "flex flex-flow items-start justify-center lg:gap-0 lg:flex-row flex-col gap-8 xl:w-[90%] w-full mx-auto  overflow-hidden pt-10 relative box-border"
          }
        />
        {pagedata.cta && <Cta Data={pagedata.cta[0]} />}
        <Footer />
      </div>
    </>
  );
};

export default ProductDetail;
