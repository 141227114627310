import React, { useContext } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Cta from "./components/Cta/Index";
import ContactForm from "./components/ContactForm";
import Banner from "./components/Banner";
import { StaticDataContext } from "./context/StaticDataContext ";
const ContactUs = () => {
  const { apiData, loading, error } = useContext(StaticDataContext);
  const pagedata = apiData.about;
  if (loading)
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-white">
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-red-600">
        Error: {error}
      </div>
    );
  if (!apiData) return null;
  return (
    <>
      <Header />
      <div className="min-h-screen flex flex-col items-center bg-[#111111]   overflow-visible p-0 relative">
        <Banner slug={"contact-me"} />
        <ContactForm />
        <Cta Data={pagedata.cta[0]} />
        <Footer />
      </div>
    </>
  );
};

export default ContactUs;
