import React, { useContext } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Cta from "./components/Cta/Index";
import MyPortfolio from "./components/MyPortfolio";
import Banner from "./components/Banner";
import { StaticDataContext } from "./context/StaticDataContext ";

const Portfolio = () => {
  const { apiData, loading, error } = useContext(StaticDataContext);
  const pagedata = apiData.about;
  if (loading)
    return (
      <div class="loader-container">
        <div class="loader"></div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;
  if (!apiData) return null;

  return (
    <>
      <Header />
      <div className="min-h-screen flex flex-col items-center bg-[#111111]   overflow-visible p-0 relative">
        <Banner slug={"my-portfolio"} />
        <MyPortfolio
          title={"Projects"}
          width={"lg:w-[47%] w-full flex flex-wrap "}
          parentClass={
            "flex flex-flow items-start justify-center lg:gap-0 lg:flex-row flex-col gap-8 xl:w-[90%] w-full mx-auto  overflow-hidden pt-10 relative box-border"
          }
        />
        <Cta Data={pagedata.cta[0]} />
        <Footer />
      </div>
    </>
  );
};

export default Portfolio;
