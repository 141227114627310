import React, { useContext } from "react";
import WebLayout from "../WebLayout";
import Heading from "../Heading";
import Card from "../ProjectCard/Card";
import { Link } from "react-router-dom";
import { StaticDataContext } from "../../context/StaticDataContext ";

const Index = ({
  title,
  width,
  showMoreProjects,
  parentClass,
  Data: parentData,
  limit,
  currentProjectSlug, // New prop to exclude current project
  shuffle = false, // New prop to enable shuffling
}) => {
  // Use the StaticDataContext
  const {
    projects: contextProjects,
    loading,
    error,
  } = useContext(StaticDataContext);

  // Determine which data to use - prioritize parentData, then context data
  const Data = parentData || contextProjects || [];

  // Shuffle array utility function
  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  // Process the data based on props
  let processedProjects = [...Data];

  // Exclude current project if currentProjectSlug is provided
  if (currentProjectSlug) {
    processedProjects = processedProjects.filter(
      (project) => project.slug !== currentProjectSlug
    );
  }

  // Shuffle projects if shuffle prop is true
  if (shuffle) {
    processedProjects = shuffleArray(processedProjects);
  }

  // Limit the projects displayed if the `limit` prop is provided
  const displayedProjects = limit
    ? processedProjects.slice(0, limit)
    : processedProjects;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <WebLayout>
      <Heading
        title={title}
        titleData={
          showMoreProjects ? (
            <Link to="/portfolio">
              <p className="text-white text-sm font-semibold leading-tight text-center xl:block hidden uppercase tracking-[2px]">
                More Projects
              </p>
            </Link>
          ) : null
        }
      >
        <div className={parentClass}>
          <div className="flex flex-wrap gap-x-4 gap-y-6">
            {displayedProjects.map((project, index) => (
              <Card
                key={project.slug || index}
                mainclass={width}
                slug={project.slug}
                imgSrc={project.imgSrc}
                title={project.title}
                tags={project.tags}
              />
            ))}
          </div>
        </div>
      </Heading>
    </WebLayout>
  );
};

export default Index;
