import React, { useContext } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Cta from "./components/Cta/Index";
import Banner from "./components/Banner";
import RoundedBtn from "./components/Button/RoundedBtn";
import { StaticDataContext } from "./context/StaticDataContext ";

const NotFound = () => {
  const { apiData, loading, error } = useContext(StaticDataContext);
  const pagedata = apiData.about;
  if (loading)
    return (
      <div class="loader-container">
        <div class="loader"></div>
      </div>
    );
  if (error) return <div>Error: {error}</div>;
  if (!apiData) return null;
  return (
    <>
      <Header />
      <div
        class="flex flex-col items-center min-h-[80vh] justify-center aos-init aos-animate"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
        data-aos-duration="800"
      >
        <div class="flex flex-col  items-center justify-start rounded-[40px]">
          <h2 class="bg-[#1c1c1c] rounded-[100px] py-3 px-8 border-t border-t-[rgba(255,255,255,0.5)]  flex items-center gap-2 text-white xl:text-sm text-[12px]   uppercase font-semibold   xl:tracking-[2px] tracking-[1px]">
            404 ERROR
          </h2>
        </div>
        <Banner
          title={"NOT FOUND"}
          description={
            "It seems the page you’re looking for has wandered off, but don’t worry—we’re here to guide you back! Feel free to head back to the homepage to continue exploring or contact us directly if you need help finding what you’re looking for. "
          }
          aboutLink={"/"}
          aboutText={""}
        />
        <RoundedBtn
          Linkto={"/"}
          buttonText={"BACK TO HOME"}
          buttonClass="dark"
        />
      </div>
      <Cta Data={pagedata.cta[0]} />
      <Footer />
    </>
  );
};

export default NotFound;
