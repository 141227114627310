import React from "react";
import { motion } from "framer-motion";
import WebLayout from "../WebLayout";

const Index = ({ mockupImage, fullImage, title }) => {
  return (
    <WebLayout>
      <div
        name="project"
        className="flex flex-col items-center gap-[80px] w-full box-border antialiased relative overflow-hidden"
      >
        <div name="banner" className="relative flex-none w-full">
          <div className="flex justify-center w-full items-center relative rounded-[24px] overflow-hidden inverted-hover md:h-[42vw] x:h-[60vw] h-[70vw]">
            {/* Mockup Image */}
            <motion.img
              decoding="async"
              src={mockupImage}
              alt={`${title} Mockup`}
              className="w-full h-auto object-cover antialiased absolute rounded-[24px]" // Use absolute for overlapping
              initial={{ opacity: 1, y: 0 }} // Start with the mockup visible
              whileHover={{ opacity: 0 }} // Fade out the mockup on hover
              transition={{
                opacity: { duration: 0.3 }, // Short duration for opacity
              }}
            />

            {/* Container for the Screenshot Image to allow scrolling */}
            <div className="relative w-full md:h-[42vw] x:h-[60vw] h-[65vw]  overflow-hidden">
              {" "}
              {/* Set height as needed */}
              <motion.img
                decoding="async"
                src={fullImage}
                alt={`${title} Screenshot`}
                className="w-full h-auto object-cover antialiased absolute" // Use absolute for scrolling effect
                initial={{ opacity: 0, y: "0%" }} // Start with the full image hidden
                whileHover={{
                  opacity: 1, // Fully opaque on hover
                  y: ["0%", "-100%", "0%"], // Scrolls from top to bottom and back to top
                }}
                transition={{
                  opacity: { duration: 0.3 }, // Short duration for opacity
                  y: {
                    duration: 90, // Long duration for scroll
                    ease: "linear", // Smooth linear scroll
                    delay: 0.8, // Delay before scrolling starts
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </WebLayout>
  );
};

export default Index;
