import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@material-tailwind/react";
import WebLayout from "../WebLayout";
import Heading from "../Heading";
import axios from "axios";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
  email: Yup.string()
    .trim()
    .email("Invalid email address")
    .required("Email is required"),
  contactNumber: Yup.string()
    .trim()
    .required("Contact number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid phone number")
    .min(10, "Phone number must be at least 10 digits"),
  message: Yup.string()
    .trim()
    .required("Message is required")
    .min(10, "Message must be at least 10 characters"),
});

const Index = () => {
  const [submitStatus, setSubmitStatus] = useState({
    isLoading: false,
    success: false,
    error: null,
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      contactNumber: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setSubmitStatus({ isLoading: true, success: false, error: null });

      try {
        const response = await axios.post(
          "https://backend-akash-singhs-projects-ff10d015.vercel.app/api/submit-form",
          values
        );

        setSubmitStatus({
          isLoading: false,
          success: true,
          error: null,
        });

        // Reset form after successful submission
        resetForm();
      } catch (error) {
        setSubmitStatus({
          isLoading: false,
          success: false,
          error:
            error.response?.data?.message ||
            "Submission failed. Please try again.",
        });
      }
    },
  });

  return (
    <WebLayout>
      <Heading
        title={"Let's Collaborate"}
        titleData={
          <div className="5xl:w-[45%] lg:w-[50%] w-full lg:pt-0 pt-8">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-4 w-full"
            >
              <div className="w-full grid grid-flow-row gap-4">
                {/* Name Input */}
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`block w-full leading-[22.4px] outline-none p-4 rounded-3xl bg-[#1c1c1c] font-medium text-[17px] text-white box-border antialiased ${
                      formik.touched.name && formik.errors.name
                        ? "border-2 border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.name}
                    </p>
                  )}
                </div>

                {/* Email Input */}
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`block w-full leading-[22.4px] outline-none p-4 rounded-3xl bg-[#1c1c1c] font-medium text-[17px] text-white box-border antialiased ${
                      formik.touched.email && formik.errors.email
                        ? "border-2 border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <p className="text-red-500 text-sm mt-1">
                      {formik.errors.email}
                    </p>
                  )}
                </div>

                {/* Contact Number Input */}
                <div>
                  <input
                    type="tel"
                    name="contactNumber"
                    placeholder="Contact Number "
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`block w-full leading-[22.4px] outline-none p-4 rounded-3xl bg-[#1c1c1c] font-medium text-[17px] text-white box-border antialiased ${
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                        ? "border-2 border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.contactNumber &&
                    formik.errors.contactNumber && (
                      <p className="text-red-500 text-sm mt-1">
                        {formik.errors.contactNumber}
                      </p>
                    )}
                </div>
              </div>

              {/* Message Textarea */}
              <div>
                <textarea
                  placeholder="Message"
                  name="message"
                  rows={6}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`block w-full leading-[22.4px] outline-none p-4 rounded-3xl bg-[#1c1c1c] font-medium text-[17px] text-white box-border antialiased ${
                    formik.touched.message && formik.errors.message
                      ? "border-2 border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.message && formik.errors.message && (
                  <p className="text-red-500 text-sm mt-1">
                    {formik.errors.message}
                  </p>
                )}
              </div>

              {/* Submit Button */}
              <Button
                type="submit"
                disabled={submitStatus.isLoading}
                className="bg-white rounded-3xl text-gray-900 font-medium text-[17px] z-10 disabled:opacity-50"
              >
                {submitStatus.isLoading ? "Submitting..." : "Submit"}
              </Button>

              {/* Submission Status Messages */}
              {submitStatus.success && (
                <p className="text-green-500 text-center mt-2">
                  Form submitted successfully!
                </p>
              )}
              {submitStatus.error && (
                <p className="text-red-500 text-center mt-2">
                  {submitStatus.error}
                </p>
              )}
            </form>
          </div>
        }
      ></Heading>
    </WebLayout>
  );
};

export default Index;
