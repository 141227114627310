import React, { useContext } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutUs from "./components/AboutUs";
import Expreince from "./components/Expreince";
import Cta from "./components/Cta/Index";
import Points from "./components/Points";
import Banner from "./components/Banner";
import Marquee from "./components/Marquee";
import { StaticDataContext } from "./context/StaticDataContext ";

const About = () => {
  const { apiData, loading, error } = useContext(StaticDataContext);

  const pagedata = apiData.about;
  if (loading)
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-white">
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-red-600">
        Error: {error}
      </div>
    );
  if (!apiData) return null;

  return (
    <>
      <Header />
      <div className="min-h-screen flex flex-col items-center bg-[#111111]  overflow-visible p-0 relative">
        <Banner slug={"about-me"} />
        <Marquee Data={pagedata.skills} title={"Skills"} />
        <AboutUs Data={pagedata.moreInfo[0]} />
        <Expreince Data={pagedata.Expreince} />
        <Points Data={pagedata.Points} />
        <Marquee Data={pagedata.workedOn[0].data} title={"Top Companies"} />
        <Cta Data={pagedata.cta[0]} />
        <Footer />
      </div>
    </>
  );
};

export default About;
