import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import WebLayout from "../WebLayout";
import Heading from "../Heading";
import "./index.css";

const Marquee = ({ Data, title }) => {
  const marqueeRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const tlRef = useRef(null);

  useEffect(() => {
    const marqueeElement = marqueeRef.current;
    const items = marqueeElement.querySelectorAll("li");

    // Calculate the total width of the items
    let totalWidth = 0;
    items.forEach((item) => {
      totalWidth += item.offsetWidth;
    });

    // Clone items for seamless looping
    const cloneContainer = document.createElement("div");
    cloneContainer.style.display = "flex";
    cloneContainer.style.flexWrap = "nowrap";
    cloneContainer.style.alignItems = "center";

    items.forEach((item) => {
      const clone = item.cloneNode(true);
      cloneContainer.appendChild(clone);
    });

    marqueeElement.appendChild(cloneContainer);

    // Create a continuous GSAP timeline
    tlRef.current = gsap.timeline({
      repeat: -1,
      defaults: { ease: "linear" },
    });

    // Continuous flow animation
    tlRef.current.fromTo(
      marqueeElement,
      { x: "0%" },
      {
        x: `-${totalWidth}px`,
        duration: totalWidth / 100,
        onComplete: () => {
          // Reset position when animation completes
          gsap.set(marqueeElement, { x: 0 });
        },
      }
    );

    // Pause and resume animation on hover
    const handleMouseEnter = () => {
      setIsPaused(true);
      tlRef.current.pause();
    };

    const handleMouseLeave = () => {
      setIsPaused(false);
      tlRef.current.resume();
    };

    marqueeElement.addEventListener("mouseenter", handleMouseEnter);
    marqueeElement.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      // Cleanup
      marqueeElement.removeEventListener("mouseenter", handleMouseEnter);
      marqueeElement.removeEventListener("mouseleave", handleMouseLeave);
      tlRef.current.kill();
    };
  }, []);

  return (
    <WebLayout>
      <Heading title={title}>
        <div className="marquee relative overflow-hidden xl:w-[90%] w-full mx-auto py-2">
          <div className="overflow-hidden mx-auto py-2">
            <ul
              ref={marqueeRef}
              className={`
                marquee 
                py-3 
                inline-flex 
                space-x-4 
                max-w-auto 
                items-center 
                ${isPaused ? "cursor-pause" : "cursor-move"}
              `}
              style={{ whiteSpace: "nowrap" }}
            >
              {Data.map((skill, index) => (
                <li
                  key={index}
                  className="flex items-center xl:space-x-6 space-x-4 xl:min-w-[250px] min-w-[180px]"
                >
                  <img
                    // title={skill.label}
                    src={skill.icon}
                    alt={skill.label}
                    className="p-5 m-3 object-contain w-full h-auto"
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Heading>
    </WebLayout>
  );
};

export default Marquee;
