import React, { useContext } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Points from "./components/Points";
import Cta from "./components/Cta/Index";
import Serviecs from "./components/Serviecs";
import Banner from "./components/Banner";
import MyPortfolio from "./components/MyPortfolio";
import Marquee from "./components/Marquee";
import { Helmet } from "react-helmet";
import { StaticDataContext } from "./context/StaticDataContext ";

const Home = () => {
  const { apiData, loading, error } = useContext(StaticDataContext);
  const pagedata = apiData.about;
  if (loading)
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-white">
        <div class="loader-container">
          <div class="loader"></div>
        </div>
      </div>
    );
  if (error)
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#111111] text-red-600">
        Error: {error}
      </div>
    );
  if (!apiData) return null;
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Akash Singh | Professional Web Developer & Custom Web Solutions
        </title>
        <meta
          name="description"
          content="Akash Singh - A professional web developer specializing in custom websites, responsive design, and modern web development. Let's build your online presence with cutting-edge solutions."
        />
        <meta
          name="keywords"
          content="web developer, custom web development, responsive design, freelance web developer, modern website design, [City/Region]"
        />

        {/* Open Graph (OG) Tags */}
        <meta
          property="og:title"
          content="Akash Singh | Professional Web Developer & Custom Web Solutions"
        />
        <meta
          property="og:description"
          content="Akash Singh - A professional web developer specializing in custom websites, responsive design, and modern web development. Let's build your online presence with cutting-edge solutions."
        />
        <meta property="og:url" content="https://akashsingh.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image:alt"
          content="Akash Singh's Portfolio Thumbnail"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Akash Singh's Portfolio" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Akash Singh | Professional Web Developer & Custom Web Solutions"
        />
        <meta
          name="twitter:description"
          content="Akash Singh - A professional web developer specializing in custom websites, responsive design, and modern web development. Let's build your online presence with cutting-edge solutions."
        />
        <meta name="twitter:url" content="https://akashsingh.com" />
        <meta
          name="twitter:image"
          content="https://akashsingh.com/thumbnail.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Akash Singh's Portfolio Thumbnail"
        />
        <meta name="twitter:site" content="@akashsingh" />
        <meta name="twitter:creator" content="@akashsingh" />

        {/* Robots Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta
          name="googlebot"
          content="index, follow, max-image-preview:large"
        />
      </Helmet>
      <Header />
      <div className="min-h-screen flex flex-col items-center bg-[#111111] overflow-visible p-0 relative">
        <Banner slug={"akash-singh"} />
        <Points Data={pagedata.Points} />
        <Marquee Data={pagedata.workedOn[0].data} title={"Top Companies"} />
        <MyPortfolio
          title={"Top Projects"}
          width={"xl:w-[90%] w-full flex-row "}
          parentClass={"  pt-10 relative gap-10 flex flex-col"}
          showMoreProjects={true}
          limit={5}
        />
        <Serviecs Data={pagedata.services} />
        <Cta Data={pagedata.cta[0]} />
        <Footer />
      </div>
    </>
  );
};

export default Home;
